import React from "react";
import { graphql } from "gatsby";
import Slideshow from "../components/slideshow";
import FaqSection from "../components/faq-section";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS } from "@contentful/rich-text-types";
import Layout from "../components/layout";
import Seo from "../components/seo";
import GradientSection from "../components/gradient-section";
import BookingBanner from "../components/booking-banner";
import { Link } from "gatsby";
import Img from "gatsby-image";
import { INLINES, MARKS } from "@contentful/rich-text-types";
import { useIntl } from "react-intl";

const Page = ({ data, pageContext: context }) => {
  const { title, main_title, subtitle, content } = data.contentfulPage;
  const intl = useIntl();
  const renderOptions = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <h2 className="h2-editorial">{children}</h2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="h3-editorial">{children}</h3>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <figure className="image-editorial">
          <Img
            fluid={node.data.target.fluid}
            alt={node.data.target.description}
          />
        </figure>
      ),
      [BLOCKS.EMBEDDED_ENTRY]: (node) => (
        <BookingBanner title={node.data.target.title} />
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data.uri} target="_blank" rel="noreferrer">
          {children}
        </a>
      ),
      [INLINES.ENTRY_HYPERLINK]: (node, children) => (
        <Link to={`/${intl.locale}/${node.data.target.slug}/`}>{children}</Link>
      ),
    },
    renderMark: {
      [MARKS.BOLD]: (text) => <strong>{text}</strong>,
      [MARKS.ITALIC]: (text) => <em>{text}</em>,
    },
    renderText: (text) => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };

  return (
    <Layout>
      <Seo title={title} description={main_title} />
      <article>
        <section className="section section-white">
          <div class="introduction introduction-clouds">
            <div class="introduction-container">
              <div class="introduction-inner">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-10">
                      {main_title ? (
                        <>
                          <p
                            className={`paragraph font-weight-bold text-center ${
                              data.contentfulHotel.spirit.slug === "travel"
                                ? "text-orange"
                                : "text-yellow"
                            }`}
                          >
                            {title}
                          </p>
                          <h1 className="text-center">{main_title}</h1>
                        </>
                      ) : (
                        <h1 className="text-center">{title}</h1>
                      )}
                      {subtitle && <p className="lead text-center">{subtitle}</p>}
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {content?.map((block) => {
          switch (block.__typename) {
            case "ContentfulTexte":
              return (
                <section class="section section-white">
                  <div class="container">
                    <div class="row justify-content-center">
                      <div class="col-lg-8">
                        <p class="lead">
                          {block.content && renderRichText(block.content,renderOptions)}
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              );

            case "ContentfulDiaporama":
              return (
                <Slideshow
                  title={block.title}
                  text={block.text}
                  slides={block.slides}
                  key={block.contentful_id}
                />
              );

            case "ContentfulFaqRubrique":
              return (
                <FaqSection
                  title={block.title}
                  questions={block.questions}
                  key={block.contentful_id}
                />
              );

            case "ContentfulGradientSection":
              return ( 
                <section className="section section-gradient">
                  <GradientSection block={block}/>
                </section>
              );

            default:
              return null;
          }
        })}  
      </article>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!, $slug: String!, $locale: String!, $hotel: String!) {
    allSitePage(filter: { context: { id: { eq: $id } } }) {
      nodes {
        path
        context {
          locale
        }
      }
    }
    allContentfulPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        contentful_id
        title
        slug
        category
      }
    }
    contentfulHotel(slug: { eq: $hotel }, node_locale: { eq: $locale }) {
      title
      meta_description
      address
      phone
      booking_id
      place_id
      business_url
      hero_image {
        file {
          url
        }
      }
      slug
      spirit {
        slug
      }
      seo_site_hotel_meta_description
    }
    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      title
      main_title
      subtitle
      category
      content {
        ... on ContentfulTexte {
          contentful_id
          __typename
          content {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                description
                fluid(maxWidth: 1920, quality: 80) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              ... on ContentfulBanniereDeReservation {
                contentful_id
                __typename
                title
              }
            }
          }
        }
        ... on ContentfulDiaporama {
          contentful_id
          __typename
          title
          text
          slides {
            ... on ContentfulActivite {
              contentful_id
              __typename
              title
              slug
              thumbnail {
                fluid(maxHeight: 320, quality: 80) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
            ... on ContentfulService {
              contentful_id
              __typename
              title
              slug
              thumbnail {
                fluid(maxHeight: 320, quality: 80) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
        ... on ContentfulFaqRubrique {
          contentful_id
          __typename
          title
          questions {
            contentful_id
            question
            answer {
              raw
            }
          }
        }
        ... on ContentfulGradientSection {
          id
          title
          __typename
          contentGradient: content {
            ... on ContentfulImageTexte {
              id
              title
              image {
                fluid(maxHeight: 650, quality: 80) {
                  ...GatsbyContentfulFluid_withWebp
                }
                title
              }
              contentText {
                raw
              }
              imageDisplay
            }
            ... on ContentfulListeDePartenaires {
              id
              title
              partners {
                id
                description
                file {
                  fileName
                  url
                  details {
                    image {
                      height
                      width
                    }
                  }
                }
              }
              bottomText
              bottomTitle
            }
          }
        }
      }
    }
  }
`;

export default Page;
